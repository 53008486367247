
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts';
import { computed, defineComponent } from 'vue'

interface PageProps {
  id: string;
  name: string;
  picture: string;
  source: string;
  link?: string;
  icon?: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    pagesList: {
      type: Array,
      required: true
    },
    sources: {
      type: Array,
      required: true
    }
  },

  emits : [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    const getSocialButton = (source: string) => {
      return socialButtons.value.find(button => button.source === source)
    }

    const selectedPageId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    return {
      selectedPageId,
      getSocialButton,
      imageFallback
    }
  }
})
